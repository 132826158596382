<template>
  <div>
    <div v-if="stillLoading" class="loading-page">
      <vue-simple-spinner size="large" message="Fetching Data..."></vue-simple-spinner>
    </div>
    <div v-else class="min-h-screen">
      <div class="mb-6 flex justify-between items-center">
        <h1 class="text-3xl font-bold">{{ $route.meta.mode }} Instructor</h1>
        <div class="">
          <Button :buttonText="modeForm === 'Edit' ? 'Update' : 'Submit'" :disabled="isUpdateButtonDisabled" @action="submitData" />
        </div>
      </div>
      <div class="tab flex justify-between items-end pl-4" v-if="modeForm === 'Edit'">
        <div class="flex gap-2">
          <div
            v-for="tab in tabList"
            :key="tab"
            @click="selectTab(tab)"
            class="tab__item text-sm px-10 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer"
            :class="[selectedTab === tab ? 'font-bold bg-white' : 'bg-neutral-50']"
          >
            {{ tab }}
          </div>
        </div>
      </div>
      <div v-if="selectedTab === 'General'">
        <div class="shadow-small rounded-lg bg-white">
          <div class="flex items-stretch shadow-border-b">
            <div class="p-6 w-1/2 shadow-border-r flex-shrink-0">
              <div v-if="isEditMode" class="mb-6">
                <TextField type="text" label="Id" :borderEnabled="true" v-model="options.id" disabled />
              </div>
              <div class="mb-6">
                <TextField type="text" label="Name" isLabelRequire placeholder="Enter Name" :borderEnabled="true" v-model="options.name" :error="errors['name']" />
              </div>
              <div class="mb-6" v-if="isEditMode">
                <TextField type="text" label="Email" isLabelRequire placeholder="Enter Email" :borderEnabled="true" v-model="options.email" :error="errors['email']" disabled />
              </div>
              <div class="mb-6" v-else>
                <TextField type="text" label="Email" isLabelRequire placeholder="Enter Email" :borderEnabled="true" v-model="options.email" :error="errors['email']" />
              </div>
              <div class="mb-6">
                <TextField
                  :type="phoneInputType"
                  isLabelRequire
                  placeholder="08xxxx"
                  label="Phone Number"
                  maxlength="15"
                  autocomplete="off"
                  :borderEnabled="true"
                  v-model="options.hp"
                  :error="errors['hp']"
                />
              </div>
              <div>
                <Dropdown
                  :error="errors['status']"
                  isLabelRequire
                  label="Status"
                  :options="statusOptions"
                  optionLabel="name"
                  v-model="statusSelected"
                  :default="statusSelected"
                  placeholder="Choose Status"
                />
              </div>
            </div>
            <div class="p-6 w-1/2 flex-shrink-0">
              <p class="text-xs font-medium mb-2"><span class="ml-3"></span>Photo</p>

              <div class="w-24">
                <img :src="options.photoUrl" v-show="options.photoUrl" />
                <div v-show="!options.photoUrl">
                  <div class="w-24 h-24">
                    <input type="file" name="photoUrl" id="photoUrl" accept=".jpg, .png, .jpeg, .gif" ref="photoUrl" @change="handleFileSelect($event, options, 'photoUrl')" class="hidden" />
                    <label for="photoUrl" class="flex items-center justify-center gap-2 cursor-pointer text-center border-dashed text-xs border border-yellow py-8 rounded-lg text-yellow px-3">
                      <span>Choose Image</span>
                    </label>
                  </div>
                </div>
                <div class="mt-6" v-show="options.photoUrl">
                  <Button buttonText="Change Image" @action="changeImage('photoUrl')" type="secondary" size="compact" />
                </div>
              </div>

              <div class="mt-8">
                <p class="text-xs font-medium"><span class="ml-3"></span>Education Background</p>
                <textarea class="mt-2" v-model="options.educationBackground"></textarea>
              </div>
              <div class="mt-8">
                <p class="text-xs font-medium"><span class="ml-3"></span>Skills (separated by coma)</p>
                <textarea class="mt-2" v-model="options.skills"></textarea>
              </div>
              <div class="mt-8">
                <p class="text-xs font-medium"><span class="ml-3"></span>Description</p>
                <textarea class="desc mt-2" v-model="options.description"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="selectedTab === 'Availability'">
        <div class="shadow-small rounded-lg bg-white">
          <div class="p-6">
            <p class="font-semibold mb-4 flex gap-1 items-center border-b border-neutral-50 py-3">
              <span class="-ml-1"><Label color="yellow" /></span>Weekly Hours
            </p>
            <div class="border border-grey-field px-4 rounded-lg">
              <div class="flex py-6 border-b border-neutral-50" v-for="(day, indexDay) in availabiltyOptions" :key="day.days">
                <div class="w-1/12 mr-5" :class="day.isAvailable ? 'mt-2' : ''">
                  <input type="checkbox" :id="day.days" v-model="day.isAvailable" class="styled-checkbox" />
                  <label :for="day.days">{{ day.days.substring(0, 3) }}</label>
                </div>
                <div class="flex justify-between w-full">
                  <div>
                    <div v-if="day.isAvailable">
                      <div class="mb-4 last:mb-0" v-for="(interval, indexInt) in day.intervals" :key="indexInt">
                        <div class="flex gap-5">
                          <div class="w-24">
                            <Dropdown
                              :options="timeOptions"
                              :disabledWhite="indexInt !== day.intervals.length - 1"
                              v-model="interval.startTime"
                              :default="interval.startTime"
                              placeholder="Choose Time"
                              :indexData="indexDay"
                              @onChangeSelected="handleTimeChanged"
                            />
                          </div>
                          <div class="my-auto">-</div>
                          <div class="w-24">
                            <Dropdown
                              :options="timeOptions"
                              :disabledWhite="indexInt !== day.intervals.length - 1"
                              v-model="interval.endTime"
                              :default="interval.endTime"
                              placeholder="Choose Time"
                              :indexData="indexDay"
                              @onChangeSelected="handleTimeChanged"
                            />
                          </div>
                          <div class="my-auto" v-if="indexInt === day.intervals.length - 1">
                            <Button type="deleteTertiary" size="icon" :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')" @action="deleteInterval(indexDay, indexInt)" />
                          </div>
                        </div>
                      </div>
                      <div class="-mt-3">
                        <transition name="fade" mode="out-in">
                          <div class="text-system-error text-left text-sm" v-if="day.error">
                            {{ day.error }}
                          </div>
                        </transition>
                      </div>
                    </div>
                    <div v-else class="text-neutral-300 font-medium">Unavailable</div>
                  </div>
                  <div v-if="day.isAvailable && !day.error && day.intervals[day.intervals.length - 1].endTime !== timeOptions[timeOptions.length - 1]">
                    <Button @action="addInterval(indexDay)" type="tertiary" size="custom" buttonText="+" additionalClass="text-lg px-2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { isNotEmptyErrorForm, showVueToast } from '@/utils'
import ImageUploadMixin from '@/mixins/image-upload'
import { mapActions } from 'vuex'
import moment from 'moment'
import { thisExpression } from '@babel/types'

const CONFIG = {
  name: 'Name',
  email: 'Email',
  hp: 'Phone Number',
  status: 'Status'
}

export default {
  mixins: [ImageUploadMixin],
  name: 'KelasForm',
  mounted: function () {
    this.modeForm = this.$route.meta.mode
    if (this.modeForm == 'Edit') {
      this.idForm = this.$route.params.id
    }
    this.initData()
  },
  data: function () {
    return {
      tabList: ['General', 'Availability'],
      selectedTab: 'General',
      availabiltyOptions: [
        {
          days: 'SUNDAY',
          isAvailable: false,
          error: null,
          intervals: [
            {
              startTime: '09:00',
              endTime: '17:00'
            }
          ]
        },
        {
          days: 'MONDAY',
          isAvailable: false,
          error: null,
          intervals: [
            {
              startTime: '09:00',
              endTime: '17:00'
            }
          ]
        },
        {
          days: 'TUESDAY',
          isAvailable: false,
          error: null,
          intervals: [
            {
              startTime: '09:00',
              endTime: '17:00'
            }
          ]
        },
        {
          days: 'WEDNESDAY',
          isAvailable: false,
          error: null,
          intervals: [
            {
              startTime: '09:00',
              endTime: '17:00'
            }
          ]
        },
        {
          days: 'THURSDAY',
          isAvailable: false,
          error: null,
          intervals: [
            {
              startTime: '09:00',
              endTime: '17:00'
            }
          ]
        },
        {
          days: 'FRIDAY',
          isAvailable: false,
          error: null,
          intervals: [
            {
              startTime: '09:00',
              endTime: '17:00'
            }
          ]
        },
        {
          days: 'SATURDAY',
          isAvailable: false,
          error: null,
          intervals: [
            {
              startTime: '09:00',
              endTime: '17:00'
            }
          ]
        }
      ],
      timeOptions: [
        // '00:00',
        // '00:15',
        // '00:30',
        // '00:45',
        // '01:00',
        // '01:15',
        // '01:30',
        // '01:45',
        // '02:00',
        // '02:15',
        // '02:30',
        // '02:45',
        // '03:00',
        // '03:15',
        // '03:30',
        // '03:45',
        // '04:00',
        // '04:15',
        // '04:30',
        // '04:45',
        // '05:00',
        // '05:15',
        // '05:30',
        // '05:45',
        // '06:00',
        // '06:15',
        // '06:30',
        // '06:45',
        '07:00',
        '07:15',
        '07:30',
        '07:45',
        '08:00',
        '08:15',
        '08:30',
        '08:45',
        '09:00',
        '09:15',
        '09:30',
        '09:45',
        '10:00',
        '10:15',
        '10:30',
        '10:45',
        '11:00',
        '11:15',
        '11:30',
        '11:45',
        '12:00',
        '12:15',
        '12:30',
        '12:45',
        '13:00',
        '13:15',
        '13:30',
        '13:45',
        '14:00',
        '14:15',
        '14:30',
        '14:45',
        '15:00',
        '15:15',
        '15:30',
        '15:45',
        '16:00',
        '16:15',
        '16:30',
        '16:45',
        '17:00',
        '17:15',
        '17:30',
        '17:45',
        '18:00',
        '18:15',
        '18:30',
        '18:45',
        '19:00',
        '19:15',
        '19:30',
        '19:45',
        '20:00',
        '20:15',
        '20:30',
        '20:45',
        '21:00',
        '21:15',
        '21:30',
        '21:45',
        '22:00',
        '22:15',
        '22:30',
        '22:45',
        '23:00',
        '23:15',
        '23:30',
        '23:45',
        '23:59'
      ],
      errors: [],
      options: {
        id: 0,
        email: '',
        name: '',
        hp: '',
        provinsi_id: 0,
        city_id: 0,
        password: '',
        provinsi: '',
        photoUrl: '',
        educationBackground: '',
        skills: '',
        description: ''
      },
      showProgress: true,
      counterProgress: 0,
      idForm: 0,
      modeForm: null,
      columns: [
        { field: 'id', label: 'Id' },
        { field: 'name', label: 'Name' },
        { field: 'hp', label: 'Phone Number' },
        { field: 'email', label: 'Email' },
        { field: 'password', label: 'Password' }
      ],
      masters: {},
      customer: {},
      levelId: 0,
      locked: false,
      stillLoading: true,
      dropzoneLogoOptions: {
        url: window.axios.defaults.baseURL + 'api/attachment',
        maxFilesize: 1,
        maxFiles: 1,
        addRemoveLinks: true,
        headers: {
          Authorization: 'jwt ' + this.$store.getters['customer/customer'].token
        }
      },
      showProfileLogo: false,
      profileLogo: '',
      edited: {
        isEditDetai: false
      },
      fileName: '',
      typeImg: '',
      phoneInputType: 'number',
      statusSelected: null,
      statusOptions: [
        {
          name: 'Freelance',
          id: 'FREELANCE'
        },
        {
          name: 'Fulltime',
          id: 'FULL_TIME'
        }
      ]
    }
  },
  methods: {
    ...mapActions('instructur', ['GET_SCHEDULES', 'SET_SCHEDULES']),
    ...mapActions('common', ['showLoading', 'hideLoading']),
    selectTab(tab) {
      this.selectedTab = tab
    },
    handleTimeChanged(value, indexDay) {
      const currentStartime = parseInt(this.availabiltyOptions[indexDay].intervals[this.availabiltyOptions[indexDay].intervals.length - 1].startTime.replace(':', ''))
      const currentEndtime = parseInt(this.availabiltyOptions[indexDay].intervals[this.availabiltyOptions[indexDay].intervals.length - 1].endTime.replace(':', ''))

      if (currentStartime > currentEndtime) {
        this.availabiltyOptions[indexDay].error = 'Choose an end time later than the start time'
        return
      }

      if (currentStartime === currentEndtime) {
        this.availabiltyOptions[indexDay].error = 'Start time and end time cannot be the same'
        return
      }
      this.availabiltyOptions[indexDay].error = null

      if (this.availabiltyOptions[indexDay].intervals.length > 1) {
        const currentStartimeBefore = parseInt(this.availabiltyOptions[indexDay].intervals[this.availabiltyOptions[indexDay].intervals.length - 2].startTime.replace(':', ''))
        const currentEndimeBefore = parseInt(this.availabiltyOptions[indexDay].intervals[this.availabiltyOptions[indexDay].intervals.length - 2].endTime.replace(':', ''))

        if (currentStartime < currentStartimeBefore || currentEndtime < currentStartimeBefore || currentStartime < currentEndimeBefore || currentEndtime < currentEndimeBefore) {
          this.availabiltyOptions[indexDay].error = 'Time overlap with another set of times'
          return
        }
        this.availabiltyOptions[indexDay].error = null
      }
    },
    addInterval(indexDay) {
      const currentEndtime = this.availabiltyOptions[indexDay].intervals[this.availabiltyOptions[indexDay].intervals.length - 1].endTime
      const currentEndtimeIndex = this.timeOptions.indexOf(currentEndtime)
      let startTime = ''
      let endTime = ''
      if (currentEndtimeIndex + 4 > this.timeOptions.length - 1) {
        startTime = this.timeOptions[currentEndtimeIndex + 1]
        endTime = this.timeOptions[this.timeOptions.length - 1]
      } else if (currentEndtimeIndex + 8 > this.timeOptions.length - 1) {
        startTime = this.timeOptions[currentEndtimeIndex + 1]
        endTime = this.timeOptions[this.timeOptions.length - 1]
      } else {
        startTime = this.timeOptions[currentEndtimeIndex + 4]
        endTime = this.timeOptions[currentEndtimeIndex + 8]
      }

      this.availabiltyOptions[indexDay].intervals.push({
        startTime,
        endTime
      })
    },
    deleteInterval(indexDay, indexInt) {
      this.availabiltyOptions[indexDay].error = null
      if (this.availabiltyOptions[indexDay].intervals.length === 1) {
        this.availabiltyOptions[indexDay].isAvailable = false
        this.availabiltyOptions[indexDay].intervals[0] = {
          startTime: '09:00',
          endTime: '17:00'
        }
        return
      }

      this.availabiltyOptions[indexDay].intervals.pop()
    },
    dropzoneLogoSuccess: function (file, response) {
      //this.options.logo = response.data.path
      this.profileLogo = response.data.path
      //this.$store.getters['customer/customer'].logo
    },
    syncTime(time) {
      const getHour = time.substring(0, 2)
      const getMinute = time.substring(2, 5)
      return getHour + getMinute
    },
    initData() {
      if (this.$route.meta.name == 'customer' && this.$route.meta.mode == 'Edit') {
        if (this.$route.params.id != this.$store.getters['customer/customer'].id) {
          this.locked = true
        }
      }
      if (this.locked) {
        return null
      }

      this.counterProgress = 0
      this.customer = this.$store.getters['customer/customer']
      if (this.modeForm == 'Edit') {
        this.showLoading()
        this.GET_SCHEDULES({
          expertId: this.expertId
        }).then((res) => {
          if (res.data.length === 0) return
          res.data.map((day) => {
            this.availabiltyOptions.map((available, index) => {
              if (day.days === available.days) {
                this.availabiltyOptions[index].isAvailable = true
                this.availabiltyOptions[index].intervals = day.intervals.map((time) => {
                  return {
                    ...time,
                    startTime: this.syncTime(time.startTime),
                    endTime: this.syncTime(time.endTime)
                  }
                })
              }
            })
          })
        })
        const customerId = this.clientId
        this.$store
          .dispatch('instructur/GET_INSTRUCTUR', {
            masterType: this.$route.meta.name,
            masterId: this.idForm,
            customerId: customerId
          })
          .then((resp) => {
            if (resp.data.code == 200) {
              let tempData = this.$store.getters['instructur/instructur']
              for (var key in tempData) {
                this.options[key] = tempData[key]
              }
              this.statusSelected = this.statusOptions.filter((stat) => stat.id === resp.data.data.employmentStatus)[0]
            } else {
              // this.$swal(resp.data.message)
            }
            this.counterProgress = 100
            this.showProgress = false
            this.stillLoading = false
            this.hideLoading()
          })
      } else {
        this.showProgress = false
        this.stillLoading = false
      }
    },
    saveAvailability() {
      const rules = this.availabiltyOptions
        .filter((available) => available.isAvailable)
        .map((time) => {
          delete time.error
          delete time.isAvailable
          const intervals = time.intervals.map((interval) => {
            return {
              ...interval,
              startTime: interval.startTime + ':00',
              endTime: interval.endTime + ':00'
            }
          })
          return {
            ...time,
            intervals
          }
        })
      const payload = {
        rules
      }

      this.SET_SCHEDULES({
        expertId: this.expertId,
        payload
      })
        .then((res) => {
          if (res.code === 200) {
            this.hideLoading()
            showVueToast('The process is successful!', 'success', 2000)
            this.$router.push('/instructor')
            return
          }
          showVueToast('Failed!', 'error', 3000)
        })
        .catch(() => {
          this.hideLoading()
          showVueToast('Email already registered!', 'error', 3000)
        })
    },

    submitData() {
      this.showLoading()
      if (!this.allValidation()) return

      if (this.modeForm == 'Edit') {
        if (this.$route.meta.name == 'customer') {
          this.options.logo = this.profileLogo
        }
        const customerId = this.clientId
        const payload = {
          name: this.options.name,
          email: this.options.email,
          hp: this.options.hp,
          photoUrl: this.options.photoUrl,
          educationBackground: this.options.educationBackground,
          skills: this.options.skills.toString().split(','),
          description: this.options.description,
          employmentStatus: this.statusSelected.id
        }
        this.$store
          .dispatch('instructur/UPDATE_INSTRUCTUR', {
            masterType: this.$route.meta.name,
            masterId: this.idForm,
            payload: payload,
            customerId: customerId
          })
          .then((resp) => {
            if (resp.data.status == '200 OK') {
              this.saveAvailability()
              return
            }
            showVueToast('Failed!', 'error', 3000)
          })
      } else {
        const customerId = this.clientId
        const payload = {
          name: this.options.name,
          email: this.options.email,
          hp: this.options.hp,
          photoUrl: this.options.photoUrl,
          educationBackground: this.options.educationBackground,
          skills: this.options.skills.split(','),
          description: this.options.description,
          employmentStatus: this.statusSelected.id
        }
        this.$store
          .dispatch('instructur/CREATE_INSTRUCTUR', {
            masterType: this.$route.meta.name,
            masterId: this.idForm,
            customerId: customerId,
            payload: payload
          })
          .then((resp) => {
            showVueToast('The process is successful!', 'success', 2000)
            if (resp.data.status == '200 OK') {
              if (this.customer.role_id > 2) {
                this.initData()
              } else {
                this.$router.push('/instructor')
              }
            }
          })
          .catch((error) => {
            this.hideLoading()
            showVueToast('Email already registered!', 'error', 3000)
          })
      }
    },
    handleFileSelect(evt, item, field) {
      this.uploadImage(evt, item, field)
    },
    changeImage(item) {
      this.$refs[item].click()
    },
    allValidation() {
      let pass = true
      const error = isNotEmptyErrorForm(this.options)
      this.errors = error.reduce(function (map, obj) {
        map[obj.field] = CONFIG[obj.field] + ' ' + obj.error
        return map
      }, {})

      if (this.options.hp === '') {
        this.errors['hp'] = "Phone Number can't be empty!"
        this.selectTab('General')
        this.hideLoading()
        pass = false
      } else if (this.options.hp[0] !== '0' || this.options.hp[1] !== '8') {
        this.errors['hp'] = 'Must start with 08xxxx'
        this.selectTab('General')

        this.hideLoading()
        pass = false
      } else if (this.options.hp.length < 10) {
        this.errors['hp'] = 'Minimum 10 digits, Maximum 15 digits'
        this.selectTab('General')
        this.hideLoading()

        pass = false
      }

      if (this.modeForm !== 'Edit') {
        const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
        if (this.options.email === '') {
          this.errors['email'] = "Email can't be empty!"
          this.selectTab('General')
          this.hideLoading()

          pass = false
        } else if (!emailPattern.test(this.options.email)) {
          this.errors['email'] = 'Incorrect email format!'
          this.selectTab('General')
          this.hideLoading()

          pass = false
        }
      }

      if (this.statusSelected === null) {
        this.selectTab('General')
        this.errors['status'] = 'Choose the status correctly!'
        showVueToast('Choose the status correctly!', 'error', 2000)
        this.hideLoading()

        pass = false
      }
      return pass
    },
    dropzoneRemovedSuccess: function (file, response) {
      this.profileLogo = ''
    }
  },
  computed: {
    isUpdateButtonDisabled() {
      return this.availabiltyOptions.filter((available) => available.error !== null).length !== 0
    },
    expertId() {
      return this.$route.params.id
    },
    clientId() {
      return localStorage.getItem('client')
    },
    data: function () {
      return this.$store.getters['instructur/list_instructur'] ? this.$store.getters['instructur/list_instructur'] : []
    },
    isEditMode() {
      return this.modeForm === 'Edit'
    },
    name() {
      return this.options.name
    },
    hp() {
      return this.options.hp
    },
    email() {
      return this.options.email
    }
  },
  created() {
    const query = this.$route.query.active_tab
    if (query) this.selectTab(query)
  },
  components: {
    vueDropzone: vue2Dropzone,
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Search: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Search'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    Label: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Icons/Label')
  },
  watch: {
    $route(to, from) {
      this.showProgress = true
      this.initData()
    },
    name(value) {
      value === '' ? (this.errors['name'] = "Name can't be empty!") : (this.errors['name'] = '')
    },
    email(value) {
      const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
      value === '' ? (this.errors['email'] = "Email can't be empty!") : (this.errors['email'] = '')
      !emailPattern.test(this.options.email) ? (this.errors['email'] = 'Incorrect email format!') : (this.errors['email'] = '')
    },
    hp(value) {
      if (value.length >= 15) {
        this.options.hp = value.slice(0, 15)
        this.phoneInputType = 'text'
      } else {
        this.phoneInputType = 'number'
      }
      if (value[0] !== '0' || value[1] !== '8') {
        this.errors['hp'] = 'Must start with 08xxxx'
      } else if (value.length < 10) {
        this.errors['hp'] = 'Minimum 10 digits, Maximum 15 digits'
      } else {
        this.errors['hp'] = ''
      }
    },
    statusSelected(value) {
      if (!value) {
        this.errors['status'] = 'Choose the status correctly!'
      } else {
        this.errors['status'] = ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
textarea {
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 12px;
  line-height: normal;
  font-size: 14px;
  resize: none;
  height: 100px;
  overflow: auto;
  outline: none;
  &.desc {
    height: 180px;
  }
}

.styled-checkbox {
  position: absolute;
  opacity: 0;
  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 0;
    display: inline-flex;
    align-items: center;
    color: #58595b;
  }
  & + label:before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid #3b82f6;
    border-radius: 4px;
    margin-right: 10px;
  }
  &:hover + label:before {
    background: #3b82f6;
  }
  &:checked + label:before {
    background: #3b82f6;
  }
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 11px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}
</style>
